main {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  text-align: center;
}

main > div:first-child {
  width: 300px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

main > div:first-child > span {
  position: absolute;
}

@media screen and (max-width: 640px) {
  main > div:first-child {
    width: 250px;
    height: 250px;
    margin-top: 30px;
  }
}

main > p {
  margin-top: 10px;
}

main > a, main > a:hover {
  color: #606060;
  text-decoration: underline;
  font-size: small;
}

#q-mint-container {
  margin-top: 70px;
}

#q-mint-container > div {
  margin-top: 5px;
}

#q-mint-container > div > p:nth-child(2) {
  margin-top: 20px;
  font-size: 0.8rem;
}

#q-mint-container > div > p:last-child {
  font-size: 0.8rem;
}

#q-countdown {
  min-width: 140px;
  padding: 10px;
  height: 60px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}