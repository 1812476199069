footer {
  text-align: center;
  font-size: small;
  font-weight: bold;
  color: #606060;
}

footer > p:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
}
footer > p:first-child > span:last-child > a {
  margin-left: 5px;
}

footer > p:last-child > a {
  text-decoration: underline;
}