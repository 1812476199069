html {
  font-family:
    'Source Sans Pro',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    sans-serif;
  font-size: 16px;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}

a, a:hover, a:focus, a:active {
  text-decoration: none;
  color: inherit;
}

body {
  min-height: 100%;
  padding: 20px 20px 10px 20px;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 640px) {
  body {
    padding: 10px 10px 5px 10px;
  }
}

#root {
  flex: 1;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
}

.q-container {
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

.q-button {
  min-width: 140px !important;
  height: 60px !important;
}
.q-button span {
  align-items: center;
  justify-content: center;
  font-weight: bold;
}