header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header > div:first-child {
  display: flex;
  align-items: center;
}

header > div:first-child > img {
  width: 60px;
  height: 60px;
  margin-right: 20px;
}

header > div:first-child > a {
  font-size: 2rem;
  font-weight: bold;
  font-family: 'Arial';
}

@media screen and (max-width: 640px) {
  header > div:first-child > img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  header > div:first-child > a {
    font-size: 1.5rem;
  }
}

header > div:last-child {
  display: flex;
  align-items: center;
}

header > div:last-child > a {
  margin-left: 10px;
}